/* src/pages/Landing/Landing.css */

.landing-page {
    min-height: 100vh;
    background-color: var(--background-default);
  }
  
  /* Achievements Section */
  .achievements-section {
    padding: 80px 0;
    background-color: var(--background-paper);
  }
  
  .section-title {
    text-align: center;
    margin-bottom: 48px !important;
    font-weight: 600 !important;
  }
  
  .achievement-card {
    padding: 32px;
    text-align: center;
    height: 100%;
    transition: all 0.3s ease;
  }
  
  .achievement-card:hover {
    transform: translateY(-8px);
  }
  
  .achievement-icon {
    color: var(--primary-main);
    font-size: 48px;
    margin-bottom: 16px;
  }
  
  .achievement-value {
    color: var(--primary-main);
    font-weight: 700 !important;
    margin-bottom: 8px !important;
  }
  
  .achievement-title {
    margin-bottom: 8px !important;
    font-weight: 600 !important;
  }
  
  .achievement-description {
    color: var(--text-secondary);
  }
  
  /* Products Section */
  .products-section {
    padding: 80px 0;
    background-color: var(--background-default);
  }
  
  .product-card {
    padding: 32px;
    height: 100%;
    transition: all 0.3s ease;
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .product-card:hover {
    transform: translateY(-8px);
    background-color: var(--primary-main);
    color: white;
  }
  
  .product-icon-wrapper {
    font-size: 48px;
    margin-bottom: 24px;
    color: var(--primary-main);
    transition: all 0.3s ease;
  }
  
  .product-card:hover .product-icon-wrapper {
    color: white;
  }
  
  .product-title {
    margin-bottom: 16px !important;
    font-weight: 600 !important;
  }
  
  .product-description {
    opacity: 0.9;
  }
  
  /* Features Section */
  .features-section {
    padding: 80px 0;
    background-color: var(--background-paper);
  }
  
  .feature-item {
    text-align: center;
    padding: 24px;
  }
  
  .feature-icon {
    color: var(--primary-main);
    font-size: 48px;
    margin-bottom: 16px;
  }
  
  .feature-title {
    margin-bottom: 8px !important;
    font-weight: 600 !important;
  }
  
  .feature-description {
    color: var(--text-secondary);
  }
  
  /* Responsive Design */
  @media screen and (max-width: 960px) {
  
    .achievements-section,
    .products-section,
    .features-section {
      padding: 60px 0;
    }
  }
  

  /* Animations */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .hero-content,
  .achievement-card,
  .product-card,
  .feature-item {
    animation: fadeInUp 0.5s ease-out;
    animation-fill-mode: both;
  }
  
  /* Accessibility */
  @media (prefers-reduced-motion: reduce) {
    .hero-content,
    .achievement-card,
    .product-card,
    .feature-item {
      animation: none;
    }
  }
  

  /* src/pages/Landing/Landing.css */


/* Add this to your Landing.css */

/* Hackathon Wins Section */
.hackathon-section {
  padding: 100px 0;
  background: linear-gradient(135deg, rgba(var(--primary-main-rgb), 0.05) 0%, rgba(var(--primary-light-rgb), 0.05) 100%);
  position: relative;
  overflow: hidden;
}

.hackathon-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
}

.section-header {
  text-align: center;
  margin-bottom: 60px;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 800;
  background: linear-gradient(135deg, var(--primary-main) 0%, var(--primary-light) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 16px;
}

.section-subtitle {
  color: var(--text-secondary);
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0 auto;
}

.hackathon-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.hackathon-card {
  background: var(--background-paper);
  border-radius: 24px;
  padding: 32px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid rgba(var(--primary-main-rgb), 0.1);
}

.hackathon-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 20px 40px rgba(var(--primary-main-rgb), 0.1);
}

.hackathon-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, var(--primary-main), var(--primary-light));
}

.hackathon-logo {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  margin-bottom: 24px;
  background: var(--background-default);
  display: flex;
  align-items: center;
  justify-content: center;
}

.hackathon-logo img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.hackathon-name {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 8px;
}

.hackathon-position {
  font-size: 1rem;
  font-weight: 600;
  color: var(--primary-main);
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.trophy-icon {
  color: #FFD700;
}

.hackathon-description {
  color: var(--text-secondary);
  font-size: 0.95rem;
  line-height: 1.6;
  margin-bottom: 24px;
}

.hackathon-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.hackathon-tag {
  padding: 6px 12px;
  background: rgba(var(--primary-main-rgb), 0.1);
  color: var(--primary-main);
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .hackathon-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .hackathon-grid {
    grid-template-columns: 1fr;
  }

  .section-title {
    font-size: 2rem;
  }
}

/* Dark mode enhancements */
[data-theme='dark'] .hackathon-card {
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.1);
}

[data-theme='dark'] .hackathon-logo {
  background: rgba(255, 255, 255, 0.1);
}

/* Animations */
.hackathon-card {
  animation: fadeInUp 0.6s ease-out forwards;
  opacity: 0;
}

.hackathon-grid > *:nth-child(2) {
  animation-delay: 0.2s;
}

.hackathon-grid > *:nth-child(3) {
  animation-delay: 0.4s;
}
