/* src/pages/About/About.css */
.about-container {
    padding: 24px;
    max-width: 1200px !important;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  .about-paper {
    padding: 32px;
    border-radius: 12px !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
  }
  
  .about-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  
  .about-title {
    margin-bottom: 16px !important;
    color: var(--text-primary);
    text-align: center;
  }
  
  .about-description {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .features-grid {
    margin-top: 16px !important;
  }
  
  .feature-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 24px;
    gap: 16px;
    height: 100%;
    border-radius: 8px;
    transition: transform 0.2s ease-in-out;
  }
  
  .feature-box:hover {
    transform: translateY(-4px);
  }
  
  .feature-icon {
    font-size: 48px !important;
    color: var(--primary-main);
  }
  
  .feature-title {
    color: var(--text-primary);
  }
  
  .about-network {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .tron-benefits {
    margin-top: 16px;
    padding-left: 24px;
  }
  
  .tron-benefits li {
    margin-bottom: 8px;
    color: var(--text-primary);
  }
  
  /* Mobile Responsive */
  @media screen and (max-width: 768px) {
    .about-container {
      padding: 16px;
    }
  
    .about-paper {
      padding: 24px;
    }
  
    .about-content {
      gap: 24px;
    }
  
    .feature-box {
      padding: 16px;
    }
  
    .feature-icon {
      font-size: 40px !important;
    }
  }
  
  /* Small mobile devices */
  @media screen and (max-width: 375px) {
    .about-container {
      padding: 12px;
    }
  
    .about-paper {
      padding: 16px;
    }
  
    .about-title {
      font-size: 1.5rem !important;
    }
  
    .feature-box {
      padding: 12px;
    }
  
    .feature-icon {
      font-size: 32px !important;
    }
  }
  