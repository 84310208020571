/* src/components/layout/Footer.css */
.footer {
  background-color: var(--background-paper);
  padding: 48px 0 24px;
  margin-top: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-grid {
  margin-bottom: 48px !important;
}

.footer-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.footer-section-title {
  font-weight: 600 !important;
  color: var(--text-primary);
  position: relative;
  padding-bottom: 12px;
}

.footer-section-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 2px;
  background-color: var(--primary-main);
  transition: width 0.3s ease;
}

.footer-section:hover .footer-section-title::after {
  width: 60px;
}

.footer-links {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer-link-wrapper {
  text-decoration: none;
  color: var(--text-secondary);
  transition: all 0.3s ease;
}

.footer-link {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.footer-link svg {
  font-size: 20px;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.footer-link-wrapper:hover {
  color: var(--primary-main);
  transform: translateX(8px);
}

.footer-link-wrapper:hover .footer-link svg {
  opacity: 1;
  transform: scale(1.1);
}

.footer-social {
  text-align: center;
  padding: 32px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.social-title {
  margin-bottom: 16px !important;
  font-weight: 600 !important;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.social-button {
  color: var(--text-secondary) !important;
  transition: all 0.3s ease !important;
}

.social-button:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.footer-bottom {
  text-align: center;
  padding-top: 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .footer {
    border-top-color: rgba(255, 255, 255, 0.1);
  }

  .footer-social,
  .footer-bottom {
    border-top-color: rgba(255, 255, 255, 0.1);
  }

  .social-button:hover {
    background-color: rgba(255, 255, 255, 0.05) !important;
  }
}

/* Mobile Responsive */
@media screen and (max-width: 768px) {
  .footer {
    padding: 32px 0 16px;
  }

  .footer-grid {
    margin-bottom: 32px !important;
  }

  .footer-section {
    gap: 16px;
  }

  .footer-links {
    gap: 12px;
  }

  .footer-social {
    padding: 24px 0;
  }
}

/* Small mobile devices */
@media screen and (max-width: 375px) {
  .footer {
    padding: 24px 0 12px;
  }

  .footer-section-title {
    font-size: 1.1rem !important;
  }

  .footer-link svg {
    font-size: 18px;
  }

  .social-links {
    flex-wrap: wrap;
    gap: 12px;
  }
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.footer-section {
  animation: fadeInUp 0.5s ease-out;
  animation-fill-mode: both;
}

.footer-section:nth-child(1) { animation-delay: 0.1s; }
.footer-section:nth-child(2) { animation-delay: 0.2s; }
.footer-section:nth-child(3) { animation-delay: 0.3s; }

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .footer-section,
  .footer-link-wrapper:hover,
  .social-button {
    animation: none;
    transform: none !important;
    transition: none !important;
  }
}
