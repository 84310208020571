/* src/components/Hackathons/Hackathons.css */
/* ... Previous styles ... */

.hackathon-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .hackathon-date {
    font-size: 0.9rem;
    color: var(--text-secondary);
    font-weight: 500;
  }
  
  .hackathon-achievements {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .achievement-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    background: rgba(var(--primary-main-rgb), 0.1);
    border-radius: 12px;
    font-weight: 500;
  }
  
  .achievement-icon {
    font-size: 1.25rem;
  }
  
  .trophy-icon {
    color: #FFD700;
  }
  
  .prize-icon {
    color: #2ECC71;
  }
  
  .hackathon-links {
    display: flex;
    gap: 12px;
    margin-top: 24px;
    padding-top: 20px;
    border-top: 1px solid rgba(var(--primary-main-rgb), 0.1);
  }
  
  .hackathon-link {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .project-link {
    background: var(--primary-main);
    color: white;
  }
  
  .project-link:hover {
    background: var(--primary-dark);
    transform: translateY(-2px);
  }
  
  .hackathon-page-link {
    background: rgba(var(--primary-main-rgb), 0.1);
    color: var(--primary-main);
  }
  
  .hackathon-page-link:hover {
    background: rgba(var(--primary-main-rgb), 0.2);
    transform: translateY(-2px);
  }
  
  .link-icon {
    font-size: 1rem;
  }
  
  /* Dark mode enhancements */
  [data-theme='dark'] .achievement-item {
    background: rgba(255, 255, 255, 0.1);
  }
  
  [data-theme='dark'] .hackathon-links {
    border-top-color: rgba(255, 255, 255, 0.1);
  }
  
  [data-theme='dark'] .hackathon-page-link {
    background: rgba(255, 255, 255, 0.1);
  }
  
  [data-theme='dark'] .hackathon-page-link:hover {
    background: rgba(255, 255, 255, 0.15);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .hackathon-links {
      flex-direction: column;
    }
  
    .hackathon-link {
      justify-content: center;
    }
  
    .achievement-item {
      flex: 1;
      justify-content: center;
    }
  }
  
  /* Hover effects */
  .hackathon-card:hover .hackathon-logo {
    transform: scale(1.05);
  }
  
  .hackathon-card:hover .achievement-item {
    transform: translateY(-2px);
  }
  
  /* Animations */
  .achievement-item {
    transition: transform 0.3s ease;
  }
  
  .hackathon-logo {
    transition: transform 0.3s ease;
  }
  