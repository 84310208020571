/* src/components/TokenView/TokenView.css */
.token-view {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.token-header {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 32px;
}

.account-info h2 {
  margin: 0 0 16px 0;
  color: #333;
}

.account-info p {
  margin: 8px 0;
  color: #666;
  word-break: break-all;
}

.tokens-container {
  background: white;
  border-radius: 12px;
  /* padding: 24px; */
}

.tokens-container h3 {
  margin: 0 0 24px 0;
  color: #333;
}

.loading-container {
  text-align: center;
  padding: 40px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #1a73e8;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 16px;
}

.error-message {
  text-align: center;
  color: #dc3545;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #dc3545;
}

.not-connected-container {
  text-align: center;
  padding: 40px;
}

.not-connected-content {
  max-width: 400px;
  margin: 0 auto;
}

.wallet-icon {
  width: 64px;
  height: 64px;
  margin-bottom: 24px;
}

.no-tokens-message {
  text-align: center;
  padding: 40px;
  background: #f5f5f5;
  border-radius: 8px;
  color: #666;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .token-header,
  .tokens-container {
    /* padding: 16px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .token-view {
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
