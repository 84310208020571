/* src/components/swap/TokenSwap.css */
.swap-container {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
}

.swap-card {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 24px;
    padding: 1.5rem;
    color: white;
}

.swap-header {
    margin-bottom: 1.5rem;
}

.swap-header h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #e2e8f0;
}

.token-search {
    margin-bottom: 1rem;
}

.search-input {
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: white;
    padding: 0.75rem 1rem;
    border-radius: 12px;
    font-size: 0.9rem;
    transition: all 0.3s ease;
}

.search-input:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.05);
}

.custom-token-input {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.custom-token-input input {
    flex: 1;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: white;
    padding: 0.75rem 1rem;
    border-radius: 12px;
    font-size: 0.9rem;
}

.custom-token-input button {
    background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
    color: white;
    border: none;
    padding: 0 1.5rem;
    border-radius: 12px;
    font-weight: 600;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.custom-token-input button:hover {
    opacity: 0.9;
}

.custom-token-input button:disabled {
    background: #4a5568;
    cursor: not-allowed;
    opacity: 0.7;
}

.token-input-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.token-input {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    padding: 1rem;
}

.token-input select {
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: white;
    padding: 0.75rem;
    border-radius: 12px;
    margin-bottom: 0.5rem;
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

.token-input-field {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.token-input-field input {
    flex: 1;
    background: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;
    padding: 0.5rem;
    outline: none;
}

.max-button {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: #4facfe;
    padding: 0.25rem 0.75rem;
    border-radius: 8px;
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.max-button:hover {
    background: rgba(255, 255, 255, 0.15);
}

.token-balance {
    color: #a0aec0;
    font-size: 0.9rem;
    margin-top: 0.5rem;
}

.swap-direction-button {
    align-self: center;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    color: white;
}

.swap-direction-button:hover {
    background: rgba(255, 255, 255, 0.15);
    transform: rotate(180deg);
}

.swap-info {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    padding: 1rem;
    margin: 1rem 0;
    font-size: 0.9rem;
}

.rate-info, .slippage-info, .minimum-received {
    display: flex;
    justify-content: space-between;
    color: #a0aec0;
    margin: 0.5rem 0;
}

.warning-message {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: rgba(236, 201, 75, 0.1);
    border: 1px solid rgba(236, 201, 75, 0.3);
    color: #fbd38d;
    padding: 1rem;
    border-radius: 12px;
    margin: 1rem 0;
}

.error-message {
    background: rgba(229, 62, 62, 0.1);
    border: 1px solid rgba(229, 62, 62, 0.3);
    color: #fc8181;
    padding: 1rem;
    border-radius: 12px;
    margin: 1rem 0;
}

.swap-button {
    width: 100%;
    background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
    color: white;
    border: none;
    padding: 1rem;
    border-radius: 12px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: opacity 0.3s ease;
    margin-top: 1rem;
}

.swap-button:hover:not(:disabled) {
    opacity: 0.9;
}

.swap-button:disabled {
    background: #4a5568;
    cursor: not-allowed;
    opacity: 0.7;
}

.loading-spinner {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.spinner {
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: white;
    animation: spin 1s linear infinite;
}

/* Slippage settings */
.slippage-settings {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
}

.slippage-settings label {
    color: #a0aec0;
    font-size: 0.9rem;
}

.slippage-settings select {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: white;
    padding: 0.5rem;
    border-radius: 8px;
    cursor: pointer;
}

/* Token groups in select */
optgroup {
    background: #2d3748;
    color: #a0aec0;
    font-weight: 600;
}

option {
    background: #2d3748;
    color: white;
    padding: 0.5rem;
}

/* Responsive design */
@media (max-width: 640px) {
    .swap-card {
        padding: 1rem;
    }

    .token-input-field input {
        font-size: 1.2rem;
    }

    .custom-token-input {
        flex-direction: column;
    }

    .custom-token-input button {
        padding: 0.75rem;
    }
}

/* Animations */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Focus states */
.token-input select:focus,
.custom-token-input input:focus,
.token-input-field input:focus {
    outline: none;
    border-color: rgba(79, 172, 254, 0.5);
}

/* Scrollbar styling */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.2);
}
