
/* Floating Achievements */
.floating-achievements {
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 16px;
    z-index: 2;
  }
  
  .achievement-card {
    padding: 16px;
    background: rgba(255, 255, 255, 0.1) !important;
    backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 16px;
    transform: translateX(100px);
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .achievement-card:hover {
    transform: translateX(0);
    background: rgba(255, 255, 255, 0.15) !important;
  }
  
  .achievement-icon {
    color: var(--primary-light);
    font-size: 32px !important;
  }
  
  .achievement-content {
    flex: 1;
  }
  
  .achievement-category {
    color: var(--primary-light);
    font-size: 0.8rem !important;
  }
  
  .achievement-title {
    color: white;
    font-size: 0.9rem !important;
    margin: 4px 0 !important;
  }
  
  .achievement-prize {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.8rem !important;
  }

  
/* Responsive Design */
@media screen and (max-width: 960px) {
  .hero-section {
    padding: 80px 0;
  }

  .hero-title {
    font-size: 3rem !important;
  }

  .hero-phone-container {
    height: 500px;
    margin-top: 40px;
  }

  .hero-phone {
    width: 240px;
    height: 480px;
  }

  .floating-achievements {
    right: 0;
  }
}

@media screen and (max-width: 600px) {
  .hero-section {
    padding: 60px 0;
  }

  .hero-title {
    font-size: 2.5rem !important;
  }

  .hero-cta {
    flex-direction: column;
  }

  .hero-stats {
    flex-direction: column;
    gap: 24px;
  }

  .stat-divider {
    display: none;
  }

  .hero-phone {
    transform: none !important;
  }

  .floating-achievements {
    position: relative;
    right: 0;
    top: 0;
    transform: none;
    margin-top: 24px;
  }

  .achievement-card {
    transform: none;
  }
}

/* Animations */
@keyframes float {
  0%, 100% {
    transform: perspective(1000px) rotateY(-15deg) translateY(0);
  }
  50% {
    transform: perspective(1000px) rotateY(-15deg) translateY(-20px);
  }
}

.hero-phone {
  animation: float 6s ease-in-out infinite;
}

.hero-phone.hovered {
  animation: none;
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .hero-phone {
    animation: none;
    transform: none !important;
  }

  .achievement-card {
    transform: none !important;
  }
}

