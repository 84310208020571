/* src/pages/Contact/Contact.css */
.contact-container {
    padding: 24px;
    max-width: 1200px !important;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  .contact-paper {
    padding: 40px;
    border-radius: 16px !important;
    background-color: var(--background-paper);
    transition: all 0.3s ease;
  }
  
  .contact-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
  }
  
  .contact-title {
    text-align: center;
    color: var(--text-primary);
    font-weight: 600 !important;
    margin-bottom: 8px !important;
  }
  
  .contact-description {
    text-align: center;
    max-width: 600px;
    margin: 0 auto !important;
    color: var(--text-secondary);
  }
  
  .social-buttons {
    display: flex;
    gap: 16px;
    justify-content: center;
    margin: 16px 0;
  }
  
  .contact-grid {
    width: 100%;
    margin-top: 16px !important;
  }
  
  .contact-link {
    text-decoration: none;
    color: inherit;
    display: block;
    height: 100%;
  }
  
  .contact-details {
    padding: 24px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.02);
    border: 2px solid transparent;
    transition: all 0.3s ease;
    height: 100%;
    box-sizing: border-box;
  }
  
  .contact-details:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .contact-details-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
  }
  
  .contact-icon {
    font-size: 24px !important;
    transition: color 0.3s ease;
  }
  
  .contact-title {
    font-weight: 500 !important;
    color: var(--text-primary);
  }
  
  .contact-content {
    color: var(--text-secondary);
  }
  
  /* Hover effects */
  .contact-details:hover .contact-icon {
    transform: scale(1.1);
  }
  
  /* Dark mode support */
  @media (prefers-color-scheme: dark) {
    .contact-details {
      background-color: rgba(255, 255, 255, 0.05);
    }
    
    .contact-details:hover {
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    }
  }
  
  /* Animations */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .contact-paper {
    animation: fadeInUp 0.5s ease-out;
  }
  
  .contact-details {
    animation: fadeInUp 0.5s ease-out;
    animation-fill-mode: both;
  }
  
  /* Stagger animation for grid items */
  .contact-grid .contact-details {
    animation-delay: calc(0.1s * var(--index));
  }
  
  /* Mobile Responsive */
  @media screen and (max-width: 768px) {
    .contact-container {
      padding: 16px;
    }
  
    .contact-paper {
      padding: 24px;
    }
  
    .contact-content {
      gap: 24px;
    }
  
    .contact-details {
      padding: 20px;
    }
  
    .social-buttons {
      gap: 12px;
    }
  }
  
  /* Small mobile devices */
  @media screen and (max-width: 375px) {
    .contact-container {
      padding: 12px;
    }
  
    .contact-paper {
      padding: 16px;
    }
  
    .contact-title {
      font-size: 1.5rem !important;
    }
  
    .contact-details {
      padding: 16px;
    }
  
    .contact-icon {
      font-size: 20px !important;
    }
  
    .social-buttons {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  
  /* Loading animation */
  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  
  .loading {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.1)
    );
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }
  