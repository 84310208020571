/* Hero Section */
.hero-section {
    padding: 80px 0;
    background: linear-gradient(135deg, var(--primary-main) 0%, var(--primary-light) 100%);
    color: white;
}

.hero-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.hero-title {
    font-weight: 700 !important;
    line-height: 1.2 !important;
    font-size: 3.5rem !important;
}

.hero-subtitle {
    opacity: 0.9;
    font-weight: 400 !important;
    line-height: 1.5 !important;
}

.highlight {
    color: var(--secondary-main);
    position: relative;
}

.hero-buttons {
    display: flex;
    gap: 16px;
    margin-top: 16px;
}

.start-button {
    padding: 12px 32px !important;
    font-size: 1.1rem !important;
}

.learn-button {
    padding: 12px 32px !important;
    font-size: 1.1rem !important;
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: white !important;
    border-color: white !important;
}


/* Responsive Design */
@media screen and (max-width: 960px) {
    .hero-title {
        font-size: 2.5rem !important;
    }

    .hero-section {
        padding: 60px 0;
    }
}

@media screen and (max-width: 600px) {
    .hero-title {
        font-size: 2rem !important;
    }

    .hero-subtitle {
        font-size: 1.2rem !important;
    }

    .hero-buttons {
        flex-direction: column;
    }

    .section-title {
        font-size: 2rem !important;
    }
}

/* Hero Section Styles */
.hero-section {
    padding: 120px 0;
    background: linear-gradient(135deg, #1a1a1a 0%, #2a2a2a 100%);
    position: relative;
    overflow: hidden;
  }
  
  .hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 50% 50%, rgba(var(--primary-main-rgb), 0.1) 0%, transparent 50%);
  }
  
  .hero-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    z-index: 1;
  }
  
  .hero-chip {
    background: rgba(255, 255, 255, 0.1) !important;
    color: var(--primary-light) !important;
    border: 1px solid rgba(var(--primary-main-rgb), 0.3) !important;
    backdrop-filter: blur(8px);
    padding: 16px 24px !important;
    height: 48px !important;
    font-size: 1rem !important;
    width: fit-content;
  }
  
  .hero-title {
    font-size: 4rem !important;
    font-weight: 700 !important;
    line-height: 1.1 !important;
    color: white;
    margin: 16px 0 !important;
  }
  
  .gradient-text {
    background: linear-gradient(45deg, var(--primary-main), var(--primary-light));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
  }
  
  .hero-subtitle {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.25rem !important;
    line-height: 1.6 !important;
    max-width: 600px;
  }
  
  .hero-cta {
    display: flex;
    gap: 16px;
    margin-top: 16px;
  }
  
  .download-button {
    padding: 12px 32px !important;
    font-size: 1.1rem !important;
    background: linear-gradient(45deg, var(--primary-main), var(--primary-light)) !important;
    border-radius: 12px !important;
    text-transform: none !important;
  }
  
  .qr-button {
    padding: 12px 32px !important;
    font-size: 1.1rem !important;
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    color: white !important;
    border-radius: 12px !important;
    backdrop-filter: blur(8px);
    text-transform: none !important;
  }
  
  .hero-stats {
    display: flex;
    gap: 48px;
    margin-top: 48px;
    padding: 24px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    backdrop-filter: blur(8px);
  }
  
  .stat-item {
    text-align: center;
  }
  
  .stat-value {
    color: white !important;
    font-weight: 700 !important;
    margin-bottom: 4px !important;
  }
  
  .stat-label {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.9rem !important;
  }
  
  .stat-divider {
    width: 1px;
    background: rgba(255, 255, 255, 0.1);
  }
  
  /* Phone Display */
  .hero-phone-container {
    position: relative;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .hero-phone {
    position: relative;
    width: 280px;
    height: 560px;
    background: #000;
    border-radius: 40px;
    padding: 12px;
    transform: perspective(1000px) rotateY(-15deg);
    transition: all 0.5s ease;
  }
  
  .hero-phone.hovered {
    transform: perspective(1000px) rotateY(0deg);
  }
  
  .phone-screen {
    width: 100%;
    height: 100%;
    border-radius: 32px;
    object-fit: cover;
  }
  
  .phone-glow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120%;
    height: 120%;
    background: var(--primary-main);
    filter: blur(60px);
    opacity: 0.15;
    z-index: -1;
  }
  

  
/* Responsive Design */
@media screen and (max-width: 960px) {
    .hero-section {
      padding: 80px 0;
    }
  
    .hero-title {
      font-size: 3rem !important;
    }
  
    .hero-phone-container {
      height: 500px;
      margin-top: 40px;
    }
  
    .hero-phone {
      width: 240px;
      height: 480px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .hero-section {
      padding: 60px 0;
    }
  
    .hero-title {
      font-size: 2.5rem !important;
    }
  
    .hero-cta {
      flex-direction: column;
    }
  
    .hero-stats {
      flex-direction: column;
      gap: 24px;
    }
  
    .stat-divider {
      display: none;
    }
  
    .hero-phone {
      transform: none !important;
    }

  }
  
  /* Animations */
  @keyframes float {
    0%, 100% {
      transform: perspective(1000px) rotateY(-15deg) translateY(0);
    }
    50% {
      transform: perspective(1000px) rotateY(-15deg) translateY(-20px);
    }
  }
  
  .hero-phone {
    animation: float 6s ease-in-out infinite;
  }
  
  .hero-phone.hovered {
    animation: none;
  }
  
  /* Accessibility */
  @media (prefers-reduced-motion: reduce) {
    .hero-phone {
      animation: none;
      transform: none !important;
    }

  }
  
  