/* src/components/swap/SwapView.css */
.swap-view {
    min-height: 100vh;
    background: linear-gradient(135deg, #1a1f2c 0%, #2d3748 100%);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.swap-view-header {
    width: 100%;
    max-width: 480px;
    margin-bottom: 1.5rem;
}

.account-info {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    padding: 1.5rem;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: white;
}

.account-info h2 {
    margin: 0 0 1rem 0;
    font-size: 1.8rem;
    font-weight: 600;
    background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.wallet-address {
    font-family: monospace;
    background: rgba(255, 255, 255, 0.1);
    padding: 0.5rem 1rem;
    border-radius: 8px;
    font-size: 0.9rem;
    margin: 0.5rem 0;
    word-break: break-all;
}

.wallet-balance {
    font-size: 1.1rem;
    color: #a0aec0;
    margin: 0.5rem 0;
}

.not-connected-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
}

.not-connected-content {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    padding: 2rem;
    text-align: center;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: white;
    max-width: 400px;
    width: 100%;
}

.not-connected-content h2 {
    margin: 1rem 0;
    font-size: 1.5rem;
    color: #e2e8f0;
}

.not-connected-content p {
    color: #a0aec0;
    margin: 0.5rem 0;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    color: white;
}

.loader {
    width: 48px;
    height: 48px;
    border: 4px solid #ffffff3d;
    border-top: 4px solid #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.error-message {
    background: rgba(229, 62, 62, 0.1);
    border: 1px solid rgba(229, 62, 62, 0.3);
    color: #fc8181;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    margin: 1rem 0;
}

/* Responsive design */
@media (max-width: 640px) {
    .swap-view {
        padding: 1rem;
    }
    
    .account-info h2 {
        font-size: 1.5rem;
    }
    
    .wallet-address {
        font-size: 0.8rem;
    }
}
