/* src/components/Layout/Layout.css */
.layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.main-content {
    flex: 1;
    margin-top: 64px;
    /* Height of header */
    padding: 2rem 1rem;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    box-sizing: border-box;
}

@media (max-width: 1024px) {
    .main-content {
        padding: 1.5rem;
        max-width: 90%;
    }
}

@media (max-width: 768px) {
    .main-content {
        padding: 1rem;
        margin-top: 56px;
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .main-content {
        padding: 0.75rem;
        margin-top: 48px;
    }
}
