/* src/pages/Terms/Terms.css */
.terms-container {
    padding: 24px;
    max-width: 1000px !important;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  .terms-paper {
    padding: 40px;
    border-radius: 16px !important;
    background-color: var(--background-paper);
    transition: all 0.3s ease;
  }
  
  .terms-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  
  .terms-title {
    text-align: center;
    color: var(--text-primary);
    font-weight: 600 !important;
    margin-bottom: 8px !important;
  }
  
  .terms-last-updated {
    text-align: center;
    color: var(--text-secondary);
    font-size: 0.9rem !important;
    margin-bottom: 24px !important;
  }
  
  .terms-introduction {
    color: var(--text-secondary);
    text-align: center;
    max-width: 800px;
    margin: 0 auto 32px auto !important;
    line-height: 1.6 !important;
  }
  
  .terms-sections {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  
  .terms-section {
    animation: fadeIn 0.5s ease-out;
    animation-fill-mode: both;
  }
  
  .section-title {
    color: var(--text-primary);
    font-weight: 600 !important;
    margin-bottom: 16px !important;
  }
  
  .section-content {
    color: var(--text-secondary);
    margin-bottom: 12px !important;
    line-height: 1.6 !important;
    text-align: justify;
  }
  
  .section-divider {
    margin-top: 24px !important;
    opacity: 0.2;
  }
  
  .terms-footer {
    margin-top: 32px;
    text-align: center;
    padding-top: 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Stagger animation for sections */
  .terms-section {
    animation-delay: calc(0.1s * var(--index));
  }
  
  /* Dark mode support */
  @media (prefers-color-scheme: dark) {
    .terms-paper {
      background-color: var(--background-paper-dark);
    }
  
    .section-divider {
      background-color: rgba(255, 255, 255, 0.1);
    }
  
    .terms-footer {
      border-top-color: rgba(255, 255, 255, 0.1);
    }
  }
  
  /* Print styles */
  @media print {
    .terms-container {
      padding: 0;
    }
  
    .terms-paper {
      box-shadow: none !important;
      padding: 20px;
    }
  
    .section-divider {
      break-after: page;
    }
  }
  
  /* Mobile Responsive */
  @media screen and (max-width: 768px) {
    .terms-container {
      padding: 16px;
    }
  
    .terms-paper {
      padding: 24px;
    }
  
    .terms-content {
      gap: 24px;
    }
  
    .terms-sections {
      gap: 24px;
    }
  
    .section-title {
      font-size: 1.1rem !important;
    }
  
    .section-content {
      font-size: 0.9rem !important;
    }
  }
  
  /* Small mobile devices */
  @media screen and (max-width: 375px) {
    .terms-container {
      padding: 12px;
    }
  
    .terms-paper {
      padding: 16px;
    }
  
    .terms-title {
      font-size: 1.5rem !important;
    }
  
    .terms-sections {
      gap: 20px;
    }
  }
  
  /* Hover effects */
  .terms-section:hover {
    transform: translateX(8px);
    transition: transform 0.3s ease;
  }
  
  /* Accessibility */
  @media (prefers-reduced-motion: reduce) {
    .terms-section {
      animation: none;
      transform: none !important;
    }
  }
  