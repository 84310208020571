/* TokenTransfer.css */
.token-transfer-container {
  padding: 24px;
  background-color: var(--background-paper);
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  box-sizing: border-box;
}

.token-transfer-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-control {
  width: 100%;
}

.token-select {
  width: 100%;
}

.token-select-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
}

.token-logo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.token-info {
  display: flex;
  flex-direction: column;
}

.amount-input,
.address-input {
  width: 100%;
}

.max-button {
  min-width: auto;
  padding: 4px 8px;
  font-size: 0.75rem;
  margin-left: 8px;
}

.submit-button {
  margin-top: 16px;
  width: 100%;
  padding: 12px;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .token-transfer-container {
    padding: 16px;
    max-width: 400px;
    margin: 0 auto;
  }

  .token-transfer-form {
    gap: 16px;
  }

  .token-select-item {
    padding: 12px 8px;
  }

  .max-button {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}

/* Small mobile devices */
@media screen and (max-width: 375px) {
  .token-transfer-container {
    padding: 12px;
    max-width: 320px;
  }

  .token-transfer-form {
    gap: 12px;
  }

  .token-logo {
    width: 20px;
    height: 20px;
  }

  .token-select-item {
    padding: 8px;
  }

  .submit-button {
    padding: 10px;
    font-size: 0.9rem;
  }
}

/* CSS Variables */
:root {
  --background-paper: #ffffff;
  --primary-main: #556cd6;
  --primary-light: #757ce8;
  --primary-dark: #002884;
  --text-primary: #1a1a1a;
  --text-secondary: #666666;
}

/* Dark mode support */
/* @media (prefers-color-scheme: light) {
  :root {
    --background-paper: #1e1e1e;
    --text-primary: #ffffff;
    --text-secondary: rgba(255, 255, 255, 0.7);
  }

  .token-transfer-container {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
} */

/* Animations */
.token-transfer-container {
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
