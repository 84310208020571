/* src/components/Layout/Header/Header.css */
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .header-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header-left {
    display: flex;
    align-items: center;
  }
  
  .logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
  }
  
  .logo-image {
    height: 64px;
    width: auto;
    margin-right: 0.5rem;
  }
  
  .logo-text {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .header-nav {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  
  .nav-link {
    text-decoration: none;
    color: #666;
    font-weight: 500;
    transition: color 0.2s;
  }
  
  .nav-link:hover {
    color: #1a73e8;
  }
  
  .wallet-section {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .wallet-address {
    background: #f5f5f5;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.875rem;
  }
  
  .wallet-button {
    padding: 0.5rem 1rem;
    border-radius: 20px;
    border: none;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .wallet-button.connect {
    background: #1a73e8;
    color: white;
  }
  
  .wallet-button:not(.connect) {
    background: #f5f5f5;
    color: #666;
  }
  
  .mobile-menu-button {
    display: none;
  }
  
  /* Mobile Responsive Styles */
  @media (max-width: 768px) {
    .mobile-menu-button {
      display: block;
      background: none;
      border: none;
      padding: 0.5rem;
      cursor: pointer;
    }
  
    .hamburger {
      display: block;
      width: 24px;
      height: 2px;
      background: #333;
      position: relative;
      transition: all 0.3s;
    }
  
    .hamburger::before,
    .hamburger::after {
      content: '';
      position: absolute;
      width: 24px;
      height: 2px;
      background: #333;
      transition: all 0.3s;
    }
  
    .hamburger::before {
      top: -6px;
    }
  
    .hamburger::after {
      bottom: -6px;
    }
  
    .hamburger.open {
      background: transparent;
    }
  
    .hamburger.open::before {
      transform: rotate(45deg);
      top: 0;
    }
  
    .hamburger.open::after {
      transform: rotate(-45deg);
      bottom: 0;
    }
  
    .header-nav {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background: white;
      flex-direction: column;
      padding: 1rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  
    .header-nav.open {
      display: flex;
    }
  
    .wallet-section {
      flex-direction: column;
      width: 100%;
    }
  
    .wallet-button {
      width: 100%;
    }
  }
  